.spinner {
  border: 5px solid lightgray;
  border-top: 5px solid blue;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.small-spinner {
  border: 2px solid lightgray;
  border-top: 2px solid blue;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
